erwingoApp.processJsonResult = function (data, options = {}) {

    // if (typeof data != 'string') {
        // console.log("Processing JSON Result", new Date(), {data: data});
    // }

    if (typeof data == "undefined" || data == null) {
        return;
    }

    if (typeof data.update != 'undefined') {
        $('[data-api-listing]' + data.update).apiListing('update');
    }

    if (typeof data.image != 'undefined') {
        $('#p' + data.image.image_secret).attr('src', data.image.image_src);
    }

    if (typeof data.file != 'undefined') {
        $("#l" + data.file.file_secret).append(erwingoApp.genText($("#t" + data.file.file_secret).html(), data.file));
        erwingoApp.loadingDialog.console(data.file.file_datetime + " - " + data.file.file_title + "." + data.file.file_ext);
    }

    if (typeof data.logout != "undefined" && data.logout) {
        erwingoApp.autologout();
        return;
    }

    if (typeof data.dialog != "undefined") {
        $("#myModal").find(".modal-content").html(data.dialog);
        erwingoApp.loadModules("#myModal");
        $("#myModal").modal();
    }

    if (typeof data.alert != "undefined") {
        Swal.fire({title: 'Hinweis', text: data.alert, icon: 'success', timer: 2000});
    }

    if (typeof data.close_window != "undefined") {
        $("#" + data.close_window).window('close');
    }

    if (typeof data.message != 'undefined') {
        if (!data.message.title) {
            if (typeof options.onSwalClose != "undefined") {
                options.onSwalClose(null, data);
            }
        } else {
            Swal.fire({
                title: data.message.title,
                text: data.message.text,
                icon: data.message.icon,
                timer: typeof data.message.timer != "undefined" ? data.message.timer : 5000,
                html: data.message.text,
            }).then(function (result) {
                if (typeof options.onSwalClose != "undefined") {
                    options.onSwalClose(result, data);
                }
            });
        }
    }

    if (typeof data.url != "undefined") {
        window.location = data.url;
    } // Redirect to URL

    if (typeof data.refresh != "undefined") {
        window.location.reload();
    } // Reload Page

    if (typeof data.tabUrl != "undefined") {
        var win = window.open(data.tabUrl, '_blank');
        win.focus();
    } // Open URL in new Tab

    if (typeof data.reload != "undefined") {
        document.location.reload(true);
    } // Reload Page

    if (typeof data.open_window != "undefined") {
        $("<div></div>").window(data.open_window);
    }

    if (typeof data.popup != "undefined") {
        $("<div></div>").window(data.popup); // Doppelt hält besser (:
    }

    if (typeof data.loading != "undefined") {

        switch (data.loading.state) {
            case "start":
                erwingoApp.loadingDialog.start();
            case "progress":
                erwingoApp.loadingDialog.console(data.loading.console, "");
                break;
            case "stop":
                erwingoApp.loadingDialog.stop();

        }
        if (typeof data.loading.cookie != "undefined") {
            if (data.loading.state != 'stop') {
                options.data.cookie = data.loading.cookie;
                erwingoApp.ajax(options); // Wiederholen mit cookie
            }
        }
        if (typeof data.loading.progress != "undefined") {
            if (data.loading.state != 'stop') {
                erwingoApp.loadingDialog.progress(data.loading.progress.width, data.loading.progress.text);
            }
        }
    } // Start Loading Dialog

    if (typeof data.reload_css != "undefined") {
        var $css = $('link[href*="' + data.reload_css + '"');
        var href = $css.attr('href') ? $css.attr('href').split("?").shift() : '';
        href += "?" + Date.now();
        $css.attr('href', href);
    } // Reload Page

};
